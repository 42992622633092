import { Section } from '@ibiliaze/react-base';
import CompareImage from 'react-compare-image';

const PictureReveal = ({ image1, image2 }) => {
  return (
    <Section id='reviews-section' className='custom-page p-t-xl p-b-xl'>
      <CompareImage leftImage='/img/domestic.jpg' rightImage='/img/commercial.jpg' />
    </Section>
  );
};

export default PictureReveal;
