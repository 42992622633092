///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Api } from '@ibiliaze/react-base';
import {
  Form,
  Col,
  CustomButton,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ListGroupItem,
  FormGroup,
  Label,
  InputGroup,
  CustomInput,
  ListGroup,
  CloseButton,
} from '@ibiliaze/reactstrap';
import { Section } from '@ibiliaze/react-base';
import { TextType } from '@ibiliaze/react-text-type';
import { DropZone, ImageViewer } from '@ibiliaze/react-dropzone';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { putAdmin } from '../../actions/admin';
import { setAlert } from '../../actions/alert';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../utils/constants';
import { resolveHost } from '../../utils/resolveEnv';
///////////////////////////////////////////////////////////////////////////////////////API
const api = new Api(resolveHost()).api;
//////////////////////////////////////////////////////////////////////////////////////////

const Albums = ({ admin, putAdmin, setAlert }) => {
  // State
  const [inputs, setInputs] = useState({ albumName: '', description: '' });
  const [albums, setAlbums] = useState([]);
  const [modal, setModal] = useState(false);
  const [imgViewer, setImgViewer] = useState(false);
  const [selectedImg, setSelectedImg] = useState('');

  // Helper functions
  const onUpload = async (img, albumName, i) => {
    const req = async _ => {
      try {
        setAlert('Uploading image...', false, null, null, true);
        const formData = new FormData();
        formData.append('image', img);
        const res = await api.post(`api/admin/upload/${albumName}-${i}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        if (!res?.data?.secure_url) throw new Error(res?.message || 'Failed to upload image');
        const imgPath = res?.data?.secure_url;
        await putAdmin({
          albums: [
            ...albums.map(album => {
              if (album.albumName === albumName) {
                return { ...album, images: { ...album.images, [i]: imgPath } };
              } else {
                return album;
              }
            }),
          ],
        });
      } catch (e) {
        console.error(e);
        setAlert(e.message, true, null, null, false);
      }
    };

    req();
  };

  // Toggle functions
  const toggle = _ => setModal(!modal);
  const toggleImgViewer = imgSrc => {
    setImgViewer(!imgViewer);
    setSelectedImg(imgSrc);
  };

  // onChange functions
  const onInputsChange = e => setInputs(c => ({ ...c, [e.target.name]: e.target.value }));

  // onClick functions
  const onAddClick = _ => {
    try {
      setAlbums([...albums, { albumName: inputs.albumName, description: inputs.description, images: {} }]);
    } catch (e) {
      console.error(e);
    }
  };

  const onRemoveAlbumClick = i => {
    try {
      const splicedAlbums = albums;
      splicedAlbums.splice(i, 1);

      setAlbums([...splicedAlbums]);
    } catch (e) {
      console.error(e);
    }
  };

  // Lifecycle hooks
  useEffect(() => {
    try {
      if (admin?.isAuthenticated) {
        setAlbums([...admin?.admin?.albums]);
      } else {
        (async () => {
          const res = await api.get('api/admin/public');
          setAlbums(res?.data?.albums || []);
        })();
      }
    } catch (e) {
      console.error(e);
    }
  }, [admin]);

  // onSubmit functions
  const onSubmit = async e => {
    try {
      putAdmin({ albums });
      e.preventDefault();
    } catch (e) {
      console.error(e);
    }
  };

  // JSX
  return (
    <Section className='custom-page p-t-xl p-b-xl'>
      <h1 className='t-align-c'>
        <TextType strings={c.textType}></TextType>

        {!!admin && !!admin.isAuthenticated && (
          <CustomButton color='primary' onClick={toggle}>
            Add Album
          </CustomButton>
        )}
      </h1>

      <Modal isOpen={modal} toggle={toggle}>
        <Form onSubmit={onSubmit}>
          <ModalHeader toggle={toggle}>Add Album</ModalHeader>
          <ModalBody>
            <h4 className='t-align-c'>Albums</h4>

            <ListGroup>
              {albums?.map((album, i) => (
                <ListGroupItem key={i}>
                  {album.albumName}
                  <CloseButton bsSize='sm' onClick={_ => onRemoveAlbumClick(i)} style={{ float: 'right' }} />
                </ListGroupItem>
              ))}
            </ListGroup>
            <br />

            <FormGroup>
              <Label>Account Name</Label>
              <CustomInput
                placeholder='Album Title'
                name='albumName'
                value={inputs.albumName}
                onChange={onInputsChange}
              />
            </FormGroup>

            <FormGroup>
              <Label>Description</Label>
              <CustomInput
                type='textarea'
                placeholder='Description'
                name='description'
                value={inputs.description}
                onChange={onInputsChange}
              />
            </FormGroup>

            <CustomButton color='primary' onClick={onAddClick}>
              Add account
            </CustomButton>
          </ModalBody>
          <ModalFooter>
            <CustomButton type='submit' color='primary'>
              Save
            </CustomButton>
            <CustomButton onClick={toggle}>Cancel</CustomButton>
          </ModalFooter>
        </Form>
      </Modal>

      {albums?.map((album, i1) => (
        <div key={i1}>
          <h2 className='t-align-c p-t-l'>{album.albumName}</h2>
          <h5 className='t-align-c p-b-m'>{album.description}</h5>
          <Row style={{ overflow: 'hidden' }}>
            {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((col, i2) => (
              <Col md={4} key={i2}>
                {admin?.isAuthenticated ? (
                  <DropZone
                    imgSrc={album.images[i2.toString()] || '/img/album-placeholder.jpg'}
                    id={`${i1}-${i2}`}
                    imgClass='example-image'
                    onUpload={args => onUpload(args, album?.albumName, i2)}
                  />
                ) : (
                  <>
                    <img
                      onClick={_ => toggleImgViewer(album?.images[i2.toString()] || '/img/album-placeholder.jpg')}
                      className='example-image'
                      alt={`${i1}-${i2}`}
                      src={album?.images[i2.toString()] || '/img/album-placeholder.jpg'}
                    />
                    <ImageViewer modal={imgViewer} setModal={setImgViewer} imgSrc={selectedImg} id={`${i1}-${i2}`} />
                  </>
                )}
              </Col>
            ))}
          </Row>
        </div>
      ))}
    </Section>
  );
};

const mapStateToProps = state => ({
  admin: state.admin,
});

export default connect(mapStateToProps, {
  putAdmin,
  setAlert,
})(Albums);
