////////////////////////////////////////////////////////////////////////////////COMPONENTS
import { MainImage } from '@ibiliaze/reactstrap';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import About from '../Dashboard/About';
import Reviews from '../Dashboard/Reviews';
import Partners from '../Dashboard/Partners';
import SecondaryAbout from '../Dashboard/SecondaryAbout';
import Banner from '../Dashboard/Banner';
import Albums from '../Dashboard/Albums';
import Revealers from '../Dashboard/Revealers';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import constants from '../../utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

const HomePage = () => (
  <>
    <MainImage
      whiteButton={true}
      imgPath={constants.mainPic}
      facebookHandle={constants.facebook}
      instagramHandle={constants.instagram}
      youtubeChannelId={constants.youtube}
      slogan={constants.slogan}
      title={constants.name.toUpperCase()}
      onButtonClick={_ => window.open(`https://wa.me/${constants.phone2}`)}
      buttonText={constants.mainPicButton}
      infoTop='55%'
      infoLeft='35%'
      imgBrightness='0.6'
      height='500px'
      imgMobileHeight='300px'
      titleLetterSpacing='0.3rem'
      titleCentre={true}
      sloganCentre={true}
      mobileWidth={800}
      formGroup={true}
      inputPlaceHolder='Have any questions?'
    />
    <Banner />
    <About />
    <Revealers />
    <Partners />
    <Albums />
    <Reviews />
    <SecondaryAbout />
  </>
);

export default HomePage;
